import React, { Component } from 'react';
import axios from 'axios';
import DataTable from './data-table';
import DataTableNotas from './data-table-notas';
import DataTableBlog from './data-table-blog';
import PropTypes from 'prop-types';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
  } from 'react-switch-lang';
import en from '../en.json';
import th from '../th.json';
import es from '../es.json';

setTranslations({ en, th, es });
setDefaultLanguage('en');
 
// If you want to remember selected language
setLanguageCookie();

//export default class Traducciones extends Component {
class Traduccionesdos extends React.Component {

    handleSetLanguage = (key) => () => {
        setLanguage(key);
    };
    
    constructor(props) {
        super(props);
        this.state = { blogCollection: [] };
    }

    /*
    componentDidMount() {
        //var url = "https://aguitech.casidios.com/panel/ws_notas_react.php";
        var url = "https://aguitech.casidios.com/panel/ws_blog_react.php";
        var params = {
            data1: 'holamundo',
        }
        //axios.get('http://aguitech.casidios.com/panel/ws_notas_react.php')
        axios.post(url, params)
            .then(res => {
                //console.log()
                //this.setState({ usersCollection: res.data });
                console.log(res.data.resultado);
                this.setState({ blogCollection: res.data.resultado });
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    */

    dataTable() {
        return this.state.blogCollection.map((data, i) => {
            return <DataTable obj={data} key={i} />;
        });
    }
    dataTableNotas() {
        return this.state.blogCollection.map((data, i) => {
            return <DataTableNotas obj={data} key={i} />;
        });
    }
    dataTableBlog() {
        return this.state.blogCollection.map((data, i) => {
            return <DataTableBlog obj={data} key={i} />;
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="wrapper-users">
                {t('home.title')}
                {t('home.title', null, 'th')}
                {t('hello', { name: 'World' })}
                {t('fallback')}
        
                <button type="button" onClick={this.handleSetLanguage('th')}>
                Switch language TH
                </button>
                <button type="button" onClick={this.handleSetLanguage('en')}>
                Switch language EN
                </button>
                <button type="button" onClick={this.handleSetLanguage('es')}>
                Switch language ES
                </button>
            </div>
        )
    }
}


Traduccionesdos.propTypes = {
    t: PropTypes.func.isRequired,
  };
   
export default translate(Traduccionesdos);
/*
{this.dataTable()}
{this.dataTableBlog()}
*/