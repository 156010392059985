import React, { Component } from 'react';

class DataTableNotas extends Component {
    render() {
        return (
            <tr>
                <td>
                    {this.props.obj.id_nota}
                </td>
                <td className="display-linebreak">
                    {this.props.obj.nota}
                </td>
                <td>
                    {this.props.obj.id_nota}
                </td>
            </tr>
        );
    }
}

export default DataTableNotas;